export const useGraphql = async (query, variablesInput) => {
  const data = ref(null)

  // If the variables parameter isn't reactive, make it reactive
  const variables = isRef(variablesInput) ? variablesInput : ref(variablesInput)

  const executeQuery = async (variables) => {
    try {
      const result = await useAsyncQuery(query, variables)
      data.value = result.data.value
    } catch (e) {
      console.error(e)
    }
  }

  // Execute the query immediately
  await executeQuery(variables.value)

  // Watch for changes in the variables and execute the query again if they change
  watch(
    () => variables.value,
    (newVariables) => executeQuery(newVariables),
  )

  return { data }
}
